import styles from './Partners.module.css'
import ourPartners from '../../../../Images/partners/ourPartners.webp'
import { useRef, useEffect } from "react";
import polymedia from "../../../../Images/partners/polymedia.webp";
import digis from "../../../../Images/partners/digis.webp";
import applied_robotics from "../../../../Images/partners/applied_robotics.webp";
import knowledge_cloud from "../../../../Images/partners/knowledge_cloud.webp";
import edustrong from "../../../../Images/partners/edustrong.webp";
import perspective from "../../../../Images/partners/perspective.webp";
import school from "../../../../Images/partners/school.webp";
import hands from "../../../../Images/partners/hands.webp";
import three_logic from "../../../../Images/partners/3logic_group.webp";
import tusur from "../../../../Images/partners/tusur.webp";
import tgpu from "../../../../Images/partners/tgpu.webp";
import AgroTech_Avia from "../../../../Images/partners/AgroTech_Avia.webp";
import msp from "../../../../Images/msp.webp";
import mspBanner from "../../../../Images/mspBanner.webp";

function Partners() {
    const scrollRef = useHorizontalScroll();
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.PartnersWrapper}>
                <div className={styles.imagesWrapper}>
                    <div>
                        <img className={styles.firstImageWrapper} src={ourPartners} />
                    </div>
                    <div className={styles.imagesWrapperPartners} ref={scrollRef}>
                        <div style={{ whiteSpace: "nowrap" }}>
                            <div style={{ display: 'flex' }}>
                                <a href="https://www.polymedia.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={polymedia} /></a>
                                <a href="https://digis.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={digis} /></a>
                                <a href="https://appliedrobotics.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={applied_robotics} /></a>
                                <a href="https://oblakoz.ru" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={knowledge_cloud} /></a>
                                <a href="https://stronikum.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={edustrong} /></a>
                                <a href="https://perspectivatomsk.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={perspective} /></a>
                                <a href="https://gimn56.tsu.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={school} /></a>
                                <a href="http://rdkristina.tomsk.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={hands} /></a>
                                <a href="https://3logic.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={three_logic} /></a>
                                <a href="https://tusur.ru/ru" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={tusur} /></a>
                                <a href="https://www.tspu.edu.ru/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={tgpu} /></a>
                                <a href="https://agrotechsim.io/" style={{ display: 'flex', alignItems: 'center' }}><img className={styles.elementWrapper} src={AgroTech_Avia} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 275, position: 'relative', zIndex:2 }}>
                <img src={msp} />
                <div style={{ position: 'absolute', top: 0, left: 1920 / 5 * 3 - 8}}>
                    <div style={{  width: 1920 / 5 }}>
                        <a href="https://mspbank.ru/"><img src={mspBanner} /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}






export function useHorizontalScroll() {
    const elRef = useRef();
    useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = e => {
                if (e.deltaY == 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + (e.deltaY * 4),
                    behavior: "smooth"
                });
            };
            el.addEventListener("wheel", onWheel);
            return () => el.removeEventListener("wheel", onWheel);
        }
    }, []);
    return elRef;
}

export default Partners;    
